import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the DistancePipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'distance',
})
export class DistancePipe implements PipeTransform {
  /**
   */
  transform(value: string | number, ...args) {
    let distance: number = Number( value );

    if (isNaN(distance)) {
      console.error("Distance pipe received invalid value: ", value);
      return 'unknown';
    }

    if (distance > 500){
      return (distance/1000).toFixed(1) + 'km';
    }

    return value + 'm';
  }
}
