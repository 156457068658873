import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToTime'
})
export class SecondsToTimePipe implements PipeTransform {

  private pad(input: number): string {
    let result = String(input);
    if (input < 10) {
      result = '0' + result;
    }

    return result;
  }

  transform(value: any, alwaysShowMinutes?: boolean): string {
    let input = parseInt(value);
    let minutes = Math.floor(input / 60);
    let seconds = input - (minutes * 60);

    let result: string = this.pad(minutes) + ':' + this.pad(seconds);
    
    if (!alwaysShowMinutes) {
      if (minutes <= 0) {
        result = this.pad(seconds);
      }
    }

    return result;
  }


}
