import { NgModule } from '@angular/core';
import { LatlonPipe } from './latlon';
import { DistancePipe } from './distance';
import { SecondsToTimePipe } from './seconds-to-time.pipe';

@NgModule({
  declarations: [
    DistancePipe,
    LatlonPipe,
    SecondsToTimePipe
  ],
  imports: [

  ],
  exports: [
    DistancePipe,
    LatlonPipe,
    SecondsToTimePipe
  ]
})
export class PipesModule { }